import React, { ComponentProps } from 'react';
import {
  Dialog,
  makeStyles,
  createStyles,
  Theme,
  DialogProps,
  ModalProps,
  ButtonProps,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import classNames from 'classnames';
import Button from 'src/legacy/components/Button';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';

import { ModalShadow } from 'src/theme/shadows';
import { GrayThinBorder } from 'src/theme/borders';
import * as Colors from 'src/theme/colors';

import ColorUtils from 'src/utils/ColorUtils';
import { Icon } from 'copilot-design-system';

export interface ModalWrapperProps extends DialogProps {
  title?: string;
  description?: string;
  closeButton?: boolean;
  successButtonLabel?: string;
  cancelButtonLabel?: string;
  successButtonEndIcon?: JSX.Element;
  positiveAction?: boolean;
  onSuccess?: () => void;
  onClose?: ModalProps['onClose'];
  isLoading?: boolean;
  hideActions?: boolean;
  denseContent?: boolean;
  hasBackdrop?: boolean;
  disableGutters?: boolean;
  hideDividers?: boolean;
  modalType?: 'warning' | 'table';
  alertIcon?: JSX.Element;
  noCancelButton?: boolean;
  width?: number | string;
  height?: number | string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  noBorder?: boolean;
  isDeleteModal?: boolean;
  onFooterLeftButtonClick?: () => void;
  footerLeftButtonLabel?: string;
  leftButtonProps?: ButtonProps;
  disableSuccessButton?: boolean;
  actionsRenderer?: () => JSX.Element;
}
const DESKTOP_MODAL_WIDTH = 470;

interface ModalStyleProps {
  fullWidth?: boolean;
  fullHeight?: boolean;
  width?: number | string;
  height?: number | string;
  disableGutters?: boolean;
  noBorder?: boolean;
  footerHasLeftButton?: boolean; // used to determine if the footer has a left button in order to position the right buttons accordingly
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmButtonWrapper: {
      marginLeft: theme.spacing(2.5),
    },
    deleteButton: {
      backgroundColor: Colors.red,
      borderColor: ColorUtils.GetColorDarknessShades(Colors.red).dark,
      '&:hover': {
        backgroundColor: ColorUtils.GetColorDarknessShades(Colors.red).dark,
      },
    },
    dialogBackdrop: {
      backgroundColor: Colors.ModalUnderlay,
    },
    dialogPaper: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
        width: (props: ModalStyleProps) =>
          props.fullWidth === true
            ? 'auto'
            : props.width || DESKTOP_MODAL_WIDTH,
        height: (props: ModalStyleProps) =>
          props.fullHeight === true ? '100%' : props.height,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#fff',
      border: (props: ModalStyleProps) =>
        props.noBorder ? 'none' : GrayThinBorder,
      boxShadow: ModalShadow,
    },
    muiDialogTitle: {
      padding: theme.spacing(2, 3.5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5, 2),
      },
      margin: 0,
    },
    muiWarningDialogTitle: {
      paddingTop: theme.spacing(3),
    },
    muiTableModalTitle: {
      padding: theme.spacing(5, 6, 3),
    },
    muiDialogContent: {
      padding: (props: ModalStyleProps) =>
        props.disableGutters ? '0px !important' : theme.spacing(2, 3.5),
      [theme.breakpoints.down('xs')]: {
        padding: (props: ModalStyleProps) =>
          props.disableGutters ? '0px !important' : theme.spacing(1.5, 2),
      },
    },
    muiDialogActions: {
      padding: theme.spacing(2, 3.5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5, 2),
      },
      justifyContent: (props) =>
        props.footerHasLeftButton ? 'space-between' : 'flex-end',
    },
    muiDialogContentDense: {
      padding: theme.spacing(0),
    },
    muiTableModalContent: {
      padding: theme.spacing(0, 6),
    },
  }),
);

export const ModalWrapper: React.FC<ModalWrapperProps> = ({
  children,
  title,
  description,
  closeButton = false,
  successButtonLabel = 'Save',
  cancelButtonLabel = 'Cancel',
  successButtonEndIcon,
  positiveAction = true,
  onSuccess,
  onClose,
  isLoading = false,
  hideActions = false,
  denseContent = false,
  hasBackdrop = false,
  hideDividers = false,
  modalType,
  noCancelButton = false,
  fullWidth,
  noBorder = false,
  width,
  fullHeight,
  height,
  alertIcon,
  disableGutters = false,
  isDeleteModal = false,
  onFooterLeftButtonClick,
  footerLeftButtonLabel,
  leftButtonProps,
  disableSuccessButton,
  actionsRenderer,
  ...rest
}) => {
  const classes = useStyles({
    fullWidth,
    disableGutters,
    width,
    fullHeight,
    height,
    noBorder,
    footerHasLeftButton: !!footerLeftButtonLabel,
  });

  const handleClose: ModalProps['onClose'] = React.useCallback(
    (event, reason) => {
      if (reason === 'backdropClick' && isLoading) {
        return;
      }
      if (onClose) {
        onClose(event, reason);
      }
    },
    [isLoading, onClose],
  );

  const handleCloseButton: ComponentProps<typeof Button>['onClick'] =
    React.useCallback(
      (e) => {
        if (onClose) {
          onClose(e, 'backdropClick');
        }
      },
      [onClose],
    );

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: hasBackdrop ? '' : classes.dialogBackdrop,
        },
      }}
      PaperProps={{
        classes: {
          root: classes.dialogPaper,
        },
      }}
      onClose={handleClose}
      transitionDuration={0}
      disableEscapeKeyDown={isLoading}
      maxWidth={fullWidth ? false : 'sm'}
      {...rest}
    >
      {title && (
        <MuiDialogTitle
          id="dialog-title"
          className={classNames(classes.muiDialogTitle, {
            [classes.muiWarningDialogTitle]: modalType === 'warning',
            [classes.muiTableModalTitle]: modalType === 'table',
          })}
        >
          {modalType === 'warning' && alertIcon && (
            <div className="flex h-10 w-10 items-center justify-center rounded bg-none-hover-background mb-4">
              {alertIcon}
            </div>
          )}
          {modalType === 'warning' && (
            <BaseTypography
              fontType="24Medium"
              style={{ color: Colors.BlackHeadings }}
            >
              {title}
            </BaseTypography>
          )}
          {modalType === 'table' && (
            <BaseTypography fontType="18Medium">{title}</BaseTypography>
          )}
          {!modalType && (
            <BaseTypography fontType="15Medium">{title}</BaseTypography>
          )}
          {description && (
            <BaseTypography
              style={{ color: Colors.GraySmall, marginTop: '4px' }}
            >
              {description}
            </BaseTypography>
          )}
          {closeButton ? (
            <button
              onClick={handleCloseButton}
              className="absolute bg-transparent border-none right-[18px] top-[18px] cursor-pointer"
            >
              <Icon icon="Close" className="text-primary h-4 w-4" />
            </button>
          ) : null}
        </MuiDialogTitle>
      )}
      <MuiDialogContent
        dividers={!hideDividers}
        className={classNames(
          !denseContent
            ? classes.muiDialogContent
            : classes.muiDialogContentDense,
          {
            [classes.muiTableModalContent]: modalType === 'table',
          },
        )}
      >
        {children}
      </MuiDialogContent>
      {!hideActions && (
        <MuiDialogActions disableSpacing className={classes.muiDialogActions}>
          {actionsRenderer ? (
            actionsRenderer()
          ) : (
            <>
              {/* Modal secondary action */}
              {footerLeftButtonLabel && (
                <Button
                  htmlId="modal-footer-text-button"
                  data-testid="modal-footer-text-button"
                  variant="text"
                  onClick={onFooterLeftButtonClick}
                  color="primary"
                  {...leftButtonProps}
                >
                  {footerLeftButtonLabel}
                </Button>
              )}

              {/* Modal control actions */}

              <div style={{ display: 'flex' }}>
                {!noCancelButton && (
                  <Button
                    htmlId="cancel-button"
                    data-testid="cancel-button"
                    variant="contained"
                    onClick={handleCloseButton}
                    color="secondary"
                    disabled={isLoading}
                  >
                    {cancelButtonLabel}
                  </Button>
                )}
                {positiveAction && onSuccess && (
                  <div className={classes.confirmButtonWrapper}>
                    <Button
                      className={isDeleteModal ? classes.deleteButton : ''}
                      variant="contained"
                      type="submit"
                      color="primary"
                      htmlId="success-action"
                      onClick={onSuccess}
                      isLoading={isLoading}
                      endIcon={successButtonEndIcon}
                      disabled={disableSuccessButton}
                    >
                      {successButtonLabel}
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        </MuiDialogActions>
      )}
    </Dialog>
  );
};
