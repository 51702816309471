import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormikValues } from 'formik';

interface MessageDraft {
  message: string;
}

interface FormDraft {
  values: FormikValues;
  fieldIds: string[];
}

// mapping of entity types to their corresponding draft types
interface EntityDraftTypeMap {
  messages: MessageDraft;
  forms: FormDraft;
}

type DraftStateType<T> = Record<string, T>;

export type DraftState = {
  [K in keyof EntityDraftTypeMap]?: DraftStateType<EntityDraftTypeMap[K]>;
};

type SaveDraftActionType<T extends keyof EntityDraftTypeMap> = {
  entityType: T;
  entityId: string;
  draftValue: EntityDraftTypeMap[T];
};

type ClearDraftActionType = {
  entityType: keyof EntityDraftTypeMap;
  entityIds: string[];
};

const initialState: DraftState = {};

const draftSlice = createSlice({
  name: 'draft',
  initialState,
  reducers: {
    saveDraft(
      state,
      action: PayloadAction<SaveDraftActionType<keyof EntityDraftTypeMap>>,
    ) {
      const { entityType, entityId, draftValue } = action.payload;
      if (!state[entityType]) {
        state[entityType] = {};
      }
      state[entityType][entityId] = draftValue;
    },
    clearDraft(state, action: PayloadAction<ClearDraftActionType>) {
      const { entityType, entityIds } = action.payload;
      for (const entityId of entityIds) {
        if (state[entityType] && state[entityType][entityId]) {
          delete state[entityType][entityId];
        }
      }
    },
  },
});

export const { saveDraft, clearDraft } = draftSlice.actions;

export const draftReducer = draftSlice.reducer;
